import { useEffect } from 'react';
import './Team.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

const teamMembers = [
    { name: 'Karen Martirosyan', role: 'Executive Director', img: 'img/Tigranmartiroyan.png', section: 'CORE TEAM' },
    { name: 'Arevik', role: 'Project Manager', img: 'img/Arev.jpg', section: 'PROJECT MANAGERS' },
    { name: 'Ruslan', role: 'Project Manager', img: 'img/Ruslan.svg', section: 'PROJECT MANAGERS' },
    { name: 'Arman', role: 'Project Manager', img: 'img/Arman.svg', section: 'PROJECT MANAGERS' },
    { name: 'Shavarsh', role: 'Business Analyst', img: 'img/Shavo.svg', section: 'BUSINESS ANALYSTS' },
    { name: 'Davit', role: 'Business Analyst', img: 'img/Davit.jpg', section: 'BUSINESS ANALYSTS' },
    { name: 'Onik', role: 'Business Analyst', img: 'img/Onik.svg', section: 'BUSINESS ANALYSTS' },
    { name: 'Sevilya ', role: 'Business Analyst', img: 'img/Sevil.jpg', section: 'BUSINESS ANALYSTS' },
    { name: 'Artur', role: 'Business Analyst', img: 'img/ArturPol.svg', section: 'BUSINESS ANALYSTS' },
    { name: 'Daniil', role: 'Lead Software Developer', img: 'img/Daniil.svg', section: 'SOFTWARE DEVELOPERS' },
    { name: 'Grisha', role: 'Senior Software Developer', img: 'img/Grisha.svg', section: 'SOFTWARE DEVELOPERS' },
    { name: 'Hayk', role: 'Software Developer', img: 'img/hayk.svg', section: 'SOFTWARE DEVELOPERS' },
    { name: 'Roman', role: 'Software Developer', img: 'img/Roman.svg', section: 'SOFTWARE DEVELOPERS' },
    { name: 'Narek', role: 'Software Developer', img: 'img/Narek.jpg', section: 'SOFTWARE DEVELOPERS' },
    { name: 'Janik', role: 'Software Developer', img: 'img/Janik.jpg', section: 'SOFTWARE DEVELOPERS' },
    { name: 'Ashot', role: 'Software Developer', img: 'img/Ashot.svg', section: 'SOFTWARE DEVELOPERS' },
    { name: 'Hayk', role: 'Software Developer', img: 'img/Haykkka 1.svg', section: 'SOFTWARE DEVELOPERS' },
    { name: 'Mariam', role: 'Software Developer', img: 'img/Mariam.jpg', section: 'SOFTWARE DEVELOPERS' },
    { name: 'Vardan', role: 'Software Developer', img: 'img/Vardan.jpg', section: 'SOFTWARE DEVELOPERS' },
    { name: 'Naira', role: 'Data Scientist', img: 'img/Nair.jpg', section: 'AI/ML' },
    { name: 'Levon', role: 'Data Scientist', img: 'img/Levon.jpg', section: 'AI/ML' },
    { name: 'Ashkhen', role: 'Machine Learning Engineer', img: 'img/Ashkhen.jpg', section: 'AI/ML' },
    { name: 'Karen', role: 'Data Scientist', img: 'img/KarenAI.jpg', section: 'AI/ML' },
    { name: 'Anna', role: 'Data Scientist', img: 'img/Anna.png', section: 'AI/ML' },
    { name: 'Anzhela', role: 'Lead Quality Assurance Engineer', img: 'img/Anzela.svg', section: 'QUALITY ASSURANCE' },
    { name: 'Vardan', role: 'Quality Automation  Engineer', img: 'img/VardanQU.jpg', section: 'QUALITY ASSURANCE' },
    { name: 'Ruzanna', role: 'Quality Assurance Engineer', img: 'img/Ruzanna 1.svg', section: 'QUALITY ASSURANCE' },
    { name: 'Mikael', role: 'Quality Automation  Engineer', img: 'img/Miqo.png', section: 'QUALITY ASSURANCE' },
    { name: 'Elvira', role: 'Quality Assurance Engineer', img: 'img/Elvira.svg', section: 'QUALITY ASSURANCE' },
    { name: 'Melanya', role: 'Quality Assurance Engineer', img: 'img/Melanya.svg', section: 'QUALITY ASSURANCE' },
    { name: 'Nelli', role: 'Quality Assurance Engineer', img: 'img/Nelii.svg', section: 'QUALITY ASSURANCE' },
    { name: 'Gor', role: 'Data Base Administrator', img: 'img/Gor1.svg', section: 'DEVELOPMENT OPERATIONS' },
    { name: 'Norayr', role: 'Development Operations Engineer', img: 'img/Norayr.svg', section: 'DEVELOPMENT OPERATIONS' },
    { name: 'Tatevik', role: 'Chef Accountant', img: 'img/Tatev.svg', section: 'ADMINISTRATIVE PERSONNEL' },
    { name: 'Alina', role: 'Administrative Personnel', img: 'img/Alina.svg', section: 'ADMINISTRATIVE PERSONNEL' },
    { name: 'Astghik', role: 'UI/UX Desinger', img: 'img/Astghik.jpg', section: 'UI/UX' },

];

const Team = () => {
    useEffect(() => {
        Aos.init({ duration: 9000 });
    }, []);

    const renderTeamMembers = (section) => {
        return teamMembers
            .filter(member => member.section === section)
            .map((member, index) => (
                <div key={index} className='Allcards'>
                    <img src={member.img} alt={member.name} data-aos="zoom-in-down" />
                    <div className='se'>
                        <p className='Pinfo'>{member.name}</p>
                        <p className='Partners_info'>{member.role}</p>
                    </div>
                </div>
            ));
    };

    return (
        <div>
            <h1 className='Ttext'>CORE TEAM</h1>
            <div className='flex'>{renderTeamMembers('CORE TEAM')}</div>

            <h1 className='Ttext'>PROJECT MANAGERS</h1>
            <div className='flex'>{renderTeamMembers('PROJECT MANAGERS')}</div>

            <h1 className='Ttext'>BUSINESS ANALYSTS</h1>
            <div className='flex'>{renderTeamMembers('BUSINESS ANALYSTS')}</div>

            <h1 className='Ttext'>SOFTWARE DEVELOPERS</h1>
            <div className='flex'>{renderTeamMembers('SOFTWARE DEVELOPERS')}</div>
            
            <h1 className='Ttext'>UI/UX Desinger</h1>
            <div className='flex'>{renderTeamMembers('UI/UX')}</div>

            <h1 className='Ttext'>AI/ML Engineer</h1>
            <div className='flex'>{renderTeamMembers('AI/ML')}</div>

            <h1 className='Ttext'>QUALITY ASSURANCE</h1>
            <div className='flex'>{renderTeamMembers('QUALITY ASSURANCE')}</div>

            <h1 className='Ttext'>DEVELOPMENT OPERATIONS</h1>
            <div className='flex'>{renderTeamMembers('DEVELOPMENT OPERATIONS')}</div>

            <h1 className='Ttext'>ADMINISTRATIVE PERSONNEL</h1>
            <div className='flex'>{renderTeamMembers('ADMINISTRATIVE PERSONNEL')}</div>

        </div>
    );
};

export default Team;
