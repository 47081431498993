import img1 from './img/Aragin.svg';
import img2 from './img/Erkrord.svg';
import img3 from './img/Errord.svg';
import img4 from './img/Chorord.svg';
import img5 from './img/Hingerord.svg';
import img6 from './img/Vecerord.svg';
import img7 from './img/Yoter0rd.svg';
import img8 from './img/Uterord.svg';
import img9 from './img/Innerord.svg';
import img10 from './img/Taserord.svg';
import './Partners.css';

const partnersData = [
    {
        img: img1,
        name: "Kamurj",
        description: "Best Micro Finance Organization in Armenia 2022"
    },
    {
        img: img2,
        name: "Cadastre Committee",
        description: "The State Committee of the Real Property Cadastre of Armenia"
    },
    {
        img: img3,
        name: "RA State Revenue Committee",
        description: "The State Revenue Committee is the tax and customs authority of Armenia"
    },
    {
        img: img4,
        name: "Masshtab Consultancy",
        description: "Consulting company focused on project management, business intelligence, and business analytics for both public and private sectors"
    },
    {
        img: img5,
        name: "Mindwise",
        description: "Consulting company that specialized on Artificial Intelligence, Machine Learning, and Big Data analysis"
    },
    {
        img: img6,
        name: "Gazprom Armenia",
        description: "Gazprom Armenia is the main supplier of natural gas in Armenia"
    },
    {
        img: img7,
        name: "Zvartnots",
        description: "Armenia International Airports"
    },
    {
        img: img8,
        name: "QuesTrade",
        description: "Canadian Financial Institution that focuses on providing investors with alternative investment options"
    },
    {
        img: img9,
        name: "Uni Speech",
        description: "Uni Speech focused on delivering universal, standards-based, and highly efficient solutions tailored to the customer’s needs."
    },
    {
        img: img10,
        name: "Volo",
        description: "Project Management Consulting Company for the IT sector"
    }
];

const Partners = () => {
    return (
        <div>
            <h1 className="Ptext">HONORABLE PARTNERS</h1>
            <div className="flex">
                {partnersData.map((partner, index) => (
                    <div className='cards' key={index}>
                        <img src={partner.img} alt={partner.name} />
                        <div className='serr'>
                            <p className='Pinfo'>{partner.name}</p>
                            <p className='Partners_info'>{partner.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Partners;
