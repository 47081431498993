import img1 from './img/addres.svg';
import img2 from './img/phone.svg';
import img3 from './img/support.svg';
import img4 from './img/logo1.svg';
import img5 from './img/logo2.png';
import img6 from './img/logo3.png';
import './Footer.css';

const contactInfo = [
    {
        img: img1,
        text: "Armenia, Yerevan, 8 M. Baghramian 2nd d/st",
        link: "https://www.google.com/maps/place/8+M.+Baghramian+2nd+d%2Fst,+Yerevan+0019/data=!4m2!3m1!1s0x406abd13f922e7d3:0xe98cbce13512f1c?sa=X&ved=1t:242&ictx=111",
        isPhone: false,
        isEmail: false,
    },
    {
        img: img2,
        text: "+374 10 300911",
        link: "tel:+37410300911",
        isPhone: true,
        isEmail: false,
    },
    {
        img: img3,
        text: "support@daniam.am",
        link: "mailto:hsargsyan887@gmail.com",
        isPhone: false,
        isEmail: true,
    },
];

// Данные для логотипов
const logos = [img4, img5, img6];

const Footer = () => {
    return (
        <footer className="footer">
            <div className="waves">
                <div className="wave" id="wave1"></div>
                <div className="wave" id="wave2"></div>
                <div className="wave" id="wave3"></div>
                <div className="wave" id="wave4"></div>
            </div>
            <div id="info">
                <div id="DIRECTORR">
                    <div className="Armen">
                        {contactInfo.map((contact, index) => (
                            <div className="location" key={index}>
                                <img src={contact.img} alt={contact.text} />
                                <a href={contact.link} target={contact.isPhone || contact.isEmail ? '_self' : '_blank'}>
                                    <span>{contact.text}</span>
                                </a>
                            </div>
                        ))}
                    </div>
                    <div className="DIRECTORR">
                        {logos.map((logo, index) => (
                            <div className="img1" key={index}>
                                <img src={logo} alt={`Logo ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <p className="copyright">&copy;Copyright © 2023 Daniam. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
